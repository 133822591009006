import Glide from '@glidejs/glide'

window.addEventListener('load', function() {
    // simples carousel available
    new Glide('.glide--simple').mount()

    // glides Slider 

    var slider = new Glide("#slider", {
        type: 'carousel',
        preView: 1,
        autoplay: 5000
    });
    slider.mount();

    // glides Slider with card

    var kachelslider = new Glide("#slider__kacheln", {
    /* type = diese Parameter hat ('slider'/ 'carousel') mit 'carousel' spielt slider ohne ende , mit 'slider' spielt bis ende und dann sprint wieder zum start */
    type: 'carousel',
    perView: 4,
    autoplay: 5000,
    focusAt: 'center',
    breakpoints: {
        800: {
        perView: 2
        },
        480: {
        perView: 1
        }
    }
    });
    kachelslider.mount();

});

    